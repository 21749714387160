<template lang="pug">
	aside#compartilhamento
		//- button(@click="$store.commit('toggleShareAside', true)")
		button(@click="share")

			FontAwesomeIcon(:icon="icons.faShareNodes")
			strong Compartilhar
		Share
</template>

<script>
import { faShareNodes } from '@fortawesome/free-solid-svg-icons'
import Share from '@components/Share/Share'

export default {
	name: "section-compartilhamento",
	components: {
		Share
	},
	data() {
		return {
			icons: {
				faShareNodes,
			},
		}
	},
	methods: {

		share() {
			if (navigator.share) {
				navigator.share({
					title: document.title,
					url: window.location.href,
				})
				.catch(err => console.error(err));
			}

			else
				this.$store.commit('toggleShareAside', true)
		},
	},
}
</script>

<style lang="stylus" scoped src="./Compartilhamento.styl"></style>
