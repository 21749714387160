<template lang="pug">
	Aside#share(:ativo="$store.state.toggleShare")
		.container
			ul
				li
					a(:href="`https://www.facebook.com/share.php?u=${link}`", target="_blank").face
						FontAwesomeIcon(:icon="icons.faFacebookF")
				li
					a(:href="`https://twitter.com/intent/tweet?url=${link}`", target="_blank").twitter
						FontAwesomeIcon(:icon="icons.faTwitter")
				li
					a(:href="`https://www.linkedin.com/sharing/share-offsite/?url=${link}`", target="_blank").linkedin
						FontAwesomeIcon(:icon="icons.faLinkedinIn")
			.link
				p {{link}}
				button(@click="copyClip()") Copiar link
</template>

<script>
import { faFacebookF, faInstagram, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import Aside from '@components/Aside/Aside'

export default {
	name: "component-share",
	components: {
		Aside,
	},
	data() {
		return {
			icons: {
				faFacebookF,
				faInstagram,
				faTwitter,
				faLinkedinIn,
			},
			link: ''
		}
	},
	methods: {
		getLink() {
			this.link = window.location.href
		},
		copyClip() {
			navigator.clipboard.writeText(this.link)
		}
	},
	updated() {
		this.getLink()
	},
}
</script>

<style lang="stylus" scoped src="./Share.styl"></style>
