<template lang="pug">
    ul
        li(v-for="item, index in contents", :class="{ ativo: ativo == index }", @click.self="toggleAtivo(index)")
            Link(:contents="item.route")
                span(@click="toggleAtivo(index)") {{ item.name }}
                FontAwesomeIcon(:icon="icons.faChevronDown", v-if="item.items && item.items.length", @click="toggleAtivo(index)").icon
            component-navegacao-itens(:contents="item.items", v-if="item.items && item.items.length" @toggleMenuPagina="$emit('toggleMenuPagina')")
</template>

<script>
import Link from '@components/Link/Link'
import { props } from '@/mixins/component'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

export default {
    name: "component-navegacao-itens",
    props,
    components: {
        Link,
    },
    data() {
        return {
            icons: {
                faChevronDown,
            },
            ativo: 0,
        }
    },
    methods: {
        toggleAtivo(index) {
            if (this.ativo == index)
                this.ativo = null
            else
                this.ativo = index
            if (this.contents[index].items.length === 0) this.$emit('toggleMenuPagina')
        }
    },
}
</script>

<style lang="stylus" scoped src="./NavegacaoItens.styl"></style>
