<template lang="pug">
    h3#titulo 
        | {{ contents }}
        SvgIcon(data="@svgs/wave.svg", original).wave
</template>

<script>
import { props } from '@/mixins/component'

export default {
    name: "component-titulo",
    props,
}
</script>

<style lang="stylus" scoped src="./Titulo.styl"></style>