<template lang="pug">
	section#conteudo
		Titulo(:contents="title", v-if="title")
		h3(v-if="newsTitle").newsTitle {{ newsTitle }} ...
		h3(v-if="newsDate").newsDate {{ formatDate(newsDate) }}
		component(:is="item.component", :contents="item.contents", v-for="item, index in mappedComponents", :key="`${item.type}-${index}`")
		slot
</template>

<script>
import Titulo from '@components/Titulo/Titulo'
// import LinhaDoTempo from '@sections/Pagina/LinhaDoTempo/LinhaDoTempo'
// import Mosaico from '@sections/Pagina/Mosaico/Mosaico'
import Fundadores from '@sections/Pagina/Fundadores/Fundadores'

import { props as mainProps, textComponents, textDictionary } from '@/mixins/component'

const components = Object.assign({}, textComponents, {
	Titulo,
	// LinhaDoTempo,
	// Mosaico,
	Fundadores
})

const props = Object.assign({}, mainProps, {
	title: {
		type: String,
		default: '',
	},
	newsTitle: {
		type: String,
		default: '',
	},
	newsDate: {
		type: String,
	}
})

export default {
	name: "section-conteudo",
	props,
	components,
	computed: {
		mappedComponents() {
			return this.contents.map(item => {
				return { component: this.$options.components[textDictionary[item.type]], contents: item, type: item.type }
			})
		},
		formattedDate() {
			return this.formatDate(this.newsDate)
		}
	},
	methods: {
		formatDate(date) {
			const objData = new Date(date)
			let month = objData.getMonth()
			if (month < 9) {
				month = `0${month + 1}`
			}
			else {
				month += 1
			}
			let formatted = `${objData.getDate()}/${month}/${objData.getFullYear()}`
			return formatted
		},
	}
}
</script>

<style lang="stylus" scoped src="./Conteudo.styl"></style>
