<template lang="pug">
    nav#navegacao(:class="{'ativo': ativo}")
        button(@click="toggleMenuPagina()").menu
            .bars
                i.first
                i.second
            span Menu
        NavegacaoItens(:contents="contents.items", :class="{ ativo }" @toggleMenuPagina="toggleMenuPagina()").list
</template>

<script>
import NavegacaoItens from '@components/NavegacaoItens/NavegacaoItens'

import { props } from '@/mixins/component'

export default {
    name: "section-navegacao",
    props,
    components: {
        NavegacaoItens,
	},
    data() {
        return {
            ativo: false
        }
    },
    methods: {
        toggleMenuPagina() {
            this.ativo = !this.ativo
        },
    }
}
</script>

<style lang="stylus" scoped src="./Navegacao.styl"></style>
